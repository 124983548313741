<template>
  <section class="flex flex-col p-6 mb-6 text-left bg-white rounded rounded-lg shadow-md sm:p-12">
    <header class="mb-12 border-b border-gray-200">
      <ul class="hidden -mb-px lg:flex lg:flex-row">
        <template v-for="(tabData, index) in tabsData">
          <div
            v-if="tabData.isFakeTab"
            :key="index"
            class="relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150 ml-8 hover:text-gray-600 hover:border-gray-300"
            @click="onClick(tabData)"
          >
            <div class="flex">
              <p>{{ tabData.name || '' }}</p>
            </div>
          </div>
          <router-link
            v-else
            :key="'router-link' + index"
            :to="tabData.route"
            active-class="text-orange-600 border-orange-600 hover:border-orange-600 hover:text-orange-600"
            class="relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150"
            :class="{
              'ml-8': index !== 0,
              'tab-locked': tabData.locked,
              'hover:text-gray-600 hover:border-gray-300': !tabData.locked,
            }"
            exact
            @click.native.capture="onRouterLinkClick($event, tabData)"
          >
            <div class="flex">
              <p>{{ tabData.name || '' }}</p>

              <sun-popover :text-info="dirtyTooltip">
                <dot v-if="tabData.dirty" class="ml-1 mr-2 mb-2"></dot>
              </sun-popover>
            </div>
          </router-link>
        </template>
      </ul>
      <div class="relative lg:hidden" @keydown.escape="isOpen = false">
        <div class="rounded-md shadow-sm">
          <button
            type="button"
            class="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 border rounded hover:border-gray-300 hover:bg-gray-300 leading-5 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-300 active:text-gray-800 transition ease-in-out duration-150"
            :class="{
              'bg-gray-300 font-black': isOpen,
            }"
            @click="isOpen = true"
          >
            <span class="flex">
              <span>{{ activeItem ? activeItem.name : '' }}</span>
              <dot v-if="isActiveTabDirty" class="ml-1 mr-2 mb-2"></dot>
            </span>
            <sun-arrow-down-svg />
          </button>
        </div>
        <div v-if="isOpen" class="fixed inset-0" tabindex="-1" @click="isOpen = false" />
        <transition name="fade">
          <div v-if="isOpen" class="absolute left-0 z-20 w-full pr-4 mt-px ml-2 mr-auto text-left text-gray-800">
            <div class="z-20 bg-white rounded-lg shadow-lg">
              <div class="py-1">
                <router-link
                  v-for="(tabData, index) in tabsData"
                  :key="index"
                  :to="tabData.route"
                  active-class="text-orange-600"
                  class="block px-4 py-3 leading-tight hover:bg-gray-200"
                  exact
                  :class="{ 'tab-locked': tabData.locked }"
                  @click.native.capture="onRouterLinkClick($event, tabData)"
                >
                  <div class="flex">
                    <p>{{ tabData.name || '' }}</p>
                    <sun-popover class="flex-none" :text-info="dirtyTooltip">
                      <dot v-if="isTabDirty(tabData)" class="ml-1 mr-2 mb-2"></dot>
                    </sun-popover>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="isOpen" class="fixed inset-0 z-10" tabindex="-1" @click="isOpen = false" />
      </div>
    </header>
    <main>
      <transition name="fade" mode="out-in" duration="200">
        <router-view v-bind="$attrs" :is-loading="isLoading" v-on="$listeners" />
      </transition>
    </main>
  </section>
</template>

<script>
import Tab from '@/model/shared/Tab';

export default {
  name: 'Tabs',
  components: {
    dot: () => import('@/components/atoms/Dot'),
  },
  props: {
    tabsData: {
      type: Array,
      default: () =>
        /** @type Array<Tab> */
        [new Tab('Edit', { name: '' })],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    dirtyTooltip: {
      type: String,
      default: () => 'You have unsaved work',
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    activeItem() {
      return this.tabsData.find(tab => this.$route?.name === tab.route?.name);
    },
    isActiveTabDirty() {
      return this.activeItem?.dirty || false;
    },
  },
  methods: {
    onRouterLinkClick(event, tab) {
      if (tab?.locked) {
        this.preventNavigation(event);
        this.$emit('locked', tab);
      } else if (this.activeItem?.dirty) {
        if (this.activeItem?.preventNavigationIfDirty) {
          this.preventNavigation(event);
        }
        this.$emit('dirty', tab);
      } else {
        this.isOpen = false;
      }
    },
    preventNavigation(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    isTabDirty(tab) {
      return this.activeItem?.dirty && this.activeItem?.name === tab?.name;
    },
    onClick(tab) {
      this.$emit('onClick', tab);
    },
  },
};
</script>

<style scoped>
.tab-locked {
  @apply text-gray-400 cursor-not-allowed border-b-0;
}
::v-deep .v-popover.flex.items-center {
  @apply items-start;
}
</style>
