var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "flex flex-col p-6 mb-6 text-left bg-white rounded rounded-lg shadow-md sm:p-12",
    },
    [
      _c("header", { staticClass: "mb-12 border-b border-gray-200" }, [
        _c(
          "ul",
          { staticClass: "hidden -mb-px lg:flex lg:flex-row" },
          [
            _vm._l(_vm.tabsData, function (tabData, index) {
              return [
                tabData.isFakeTab
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150 ml-8 hover:text-gray-600 hover:border-gray-300",
                        on: {
                          click: function ($event) {
                            return _vm.onClick(tabData)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "flex" }, [
                          _c("p", [_vm._v(_vm._s(tabData.name || ""))]),
                        ]),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "router-link" + index,
                        staticClass:
                          "relative inline-block px-1 py-3 text-sm font-semibold text-gray-500 border-b-2 border-transparent cursor-pointer select-none transition ease-in-out duration-150",
                        class: {
                          "ml-8": index !== 0,
                          "tab-locked": tabData.locked,
                          "hover:text-gray-600 hover:border-gray-300":
                            !tabData.locked,
                        },
                        attrs: {
                          to: tabData.route,
                          "active-class":
                            "text-orange-600 border-orange-600 hover:border-orange-600 hover:text-orange-600",
                          exact: "",
                        },
                        nativeOn: {
                          "!click": function ($event) {
                            return _vm.onRouterLinkClick($event, tabData)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("p", [_vm._v(_vm._s(tabData.name || ""))]),
                            _c(
                              "sun-popover",
                              { attrs: { "text-info": _vm.dirtyTooltip } },
                              [
                                tabData.dirty
                                  ? _c("dot", { staticClass: "ml-1 mr-2 mb-2" })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "relative lg:hidden",
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                )
                  return null
                _vm.isOpen = false
              },
            },
          },
          [
            _c("div", { staticClass: "rounded-md shadow-sm" }, [
              _c(
                "button",
                {
                  staticClass:
                    "inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 border rounded hover:border-gray-300 hover:bg-gray-300 leading-5 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-300 active:text-gray-800 transition ease-in-out duration-150",
                  class: {
                    "bg-gray-300 font-black": _vm.isOpen,
                  },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "flex" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.activeItem ? _vm.activeItem.name : "")
                        ),
                      ]),
                      _vm.isActiveTabDirty
                        ? _c("dot", { staticClass: "ml-1 mr-2 mb-2" })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("sun-arrow-down-svg"),
                ],
                1
              ),
            ]),
            _vm.isOpen
              ? _c("div", {
                  staticClass: "fixed inset-0",
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                })
              : _vm._e(),
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.isOpen
                ? _c(
                    "div",
                    {
                      staticClass:
                        "absolute left-0 z-20 w-full pr-4 mt-px ml-2 mr-auto text-left text-gray-800",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "z-20 bg-white rounded-lg shadow-lg" },
                        [
                          _c(
                            "div",
                            { staticClass: "py-1" },
                            _vm._l(_vm.tabsData, function (tabData, index) {
                              return _c(
                                "router-link",
                                {
                                  key: index,
                                  staticClass:
                                    "block px-4 py-3 leading-tight hover:bg-gray-200",
                                  class: { "tab-locked": tabData.locked },
                                  attrs: {
                                    to: tabData.route,
                                    "active-class": "text-orange-600",
                                    exact: "",
                                  },
                                  nativeOn: {
                                    "!click": function ($event) {
                                      return _vm.onRouterLinkClick(
                                        $event,
                                        tabData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(tabData.name || "")),
                                      ]),
                                      _c(
                                        "sun-popover",
                                        {
                                          staticClass: "flex-none",
                                          attrs: {
                                            "text-info": _vm.dirtyTooltip,
                                          },
                                        },
                                        [
                                          _vm.isTabDirty(tabData)
                                            ? _c("dot", {
                                                staticClass: "ml-1 mr-2 mb-2",
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm.isOpen
              ? _c("div", {
                  staticClass: "fixed inset-0 z-10",
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "main",
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in", duration: "200" } },
            [
              _c(
                "router-view",
                _vm._g(
                  _vm._b(
                    { attrs: { "is-loading": _vm.isLoading } },
                    "router-view",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }